import React from "react";
import { Link } from "react-router-dom";

const Main: React.FC = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Witaj na stronie głównej!</h1>
      <p>Kliknij poniższy link, aby się zalogować:</p>
      <Link to="/login" style={{ fontSize: "20px", color: "blue" }}>
        Przejdź do logowania
      </Link>
    </div>
  );
};

export default Main;
