import * as React from "react";
import * as Yup from "yup";
import { Grid, Divider, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonPrimary } from "Components/Buttons/button-primary";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { getStoredUserAuthToken } from "Utils/helpers";
import { ApiResultStatus } from "Utils/enums";
import { IUserLoginModel } from "Services/UserApi/interfaces-model";
import { IUserLoginResult } from "Services/UserApi/interfaces-result";
import { useTranslation } from "react-i18next";
import { useUserApi } from "Services/UserApi/user-api";
import BlockUi from "react-block-ui";
import useAuthorization from "Hooks/use-auth-token";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import ButtonFacebook from "Components/Buttons/button-facebook";
import ButtonGoogle from "Components/Buttons/button-google";
import LayoutNotLogged from "Layouts/layout-not-logged";
import { PrivateRoutes } from "Routes/routes";
import i18n from "Configurations/i18n";
import { themeGsot, themeSbs } from "Styles/themes";
import ThemeLink from "Components/Links/theme-link";

const Login: () => JSX.Element = (): JSX.Element => {
  const { setUser, setAuthorize } = useAuthorization(getStoredUserAuthToken());
  const { login } = useUserApi();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const history = useHistory();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);

  const initialValues: IUserLoginModel = {
    email: "",
    password: "",
  };

  const handleLogin: (user: IUserLoginModel) => Promise<void> = async (
    user: IUserLoginModel
  ): Promise<void> => {
    setLoading(true);
    await login(user).then((response: IUserLoginResult) => {
      if (response && response.status === ApiResultStatus.Ok) {
        const newToken: string = response.data.token;
        setAuthorize(newToken);
        setUser(response.data);
      }
    });
  };

  React.useEffect(() => {
    const lang = query.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />}>
      <LayoutNotLogged>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("validationResources:emailInvalid"))
              .max(255, t("validationResources:emailMaxLength"))
              .required(t("validationResources:required")),
            password: Yup.string().required(t("validationResources:required")),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const user: IUserLoginModel = {
              email: values.email ?? "",
              password: values.password ?? "",
            };
            await handleLogin(user).then(() => {
              const redirect = query.get("redirectTo");
              if (redirect) {
                history.push(`/${redirect}`);
              } else {
                history.push(PrivateRoutes.info);
              }
            });
          }}
        >
          <Form>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <TextSubheader>{t("welcomeIn")}</TextSubheader>
                <TextHeader>
                  {window.location.href.indexOf("sbs.gsot") > 0
                    ? "SBS"
                    : "Szlaku Kopernikowskim"}
                </TextHeader>
                <Divider />
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  fullWidth
                  label={t("email")}
                  variant="outlined"
                  size="small"
                  id="email"
                  name="email"
                />
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  fullWidth
                  label={t("password")}
                  variant="outlined"
                  size="small"
                  type="password"
                  id="password"
                  name="password"
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonPrimary type="submit">{t("logIn")}</ButtonPrimary>
              </Grid>
            </Grid>
          </Form>
        </Formik>
        <Divider />
        <Grid container justify="center" spacing={1}>
          <ButtonFacebook setLoading={setLoading} />
        </Grid>
        {/* <Grid container justify="center" spacing={1}>
          <ButtonGoogle setLoading={setLoading} />
        </Grid> */}
        <Divider />
        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12}>
            <TextInfo>
              {t("forgotPassword")}{" "}
              <ThemeLink to="/reset-password-reqest" text={t("resetIt")} />
            </TextInfo>
          </Grid>
          <Grid item xs={12}>
            <TextInfo>
              {t("noAccount")}{" "}
              <ThemeLink to="/Register" text={t("registerMe")} />
            </TextInfo>
          </Grid>
          <Grid item xs={12}>
            <TextInfo>
              {t("noActivationEmail")}{" "}
              <ThemeLink to="/resend-user-confirmation" text={t("resend")} />
            </TextInfo>
          </Grid>
        </Grid>
      </LayoutNotLogged>
    </BlockUi>
  );
};

export default Login;
